.thread-message {
    max-width: 100%;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    position: relative;
  }

/* Styling for messages on the left side */
.thread-message.left {
  background-color: #f2f2f2;
  margin-left: 0;
  margin-right: auto;
}

/* Styling for messages on the right side */
.thread-message.right {
  background-color: #007bff;
  color: #fff;
  margin-left: auto;
  margin-right: 0;
}

.thread-top-row-container {
  display: flex;
  align-items: center;
  height:50px
}

.thread-amount-styling {
  line-height: 20px;
  width: 105px;
  font-size: small;
}

.thread-amount-styling-smaller {
  line-height: 20px;
  width: 80px;
  font-size: x-small;
}

.thread-total-amount-col {
  font-size: 10px; /* Adjust font size as needed */
  color: #777; /* Adjust color as needed */
  text-align: center;
  /* margin-left: 20px; */
}

.thread-button-col {
  align-self:flex-start;
}

.thread-remaining-amount-text {
  text-align: center;
}

.thread-remaining-amount-col {
  font-size: 10px; /* Adjust font size as needed */
  color: #777; /* Adjust color as needed */
  text-align: center;
  align-self: flex-end;
  width: 100px;
}

.thread-subject {
  width: 180px;
  word-wrap: break-word;
  font-size: 12px;
  align-self: center;
  font-weight: 600;
}

/* Styling for the "thread" that connects messages */
.thread {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
}

.branch-container {
  display: flex;
  align-items: center;
  /* margin: 0; */
}

.branch {
  display: flex;
  /* align-items: center; */
}

.column-container {
  display: flex;
  flex-direction: column;
}

.branch-text {
  width: 120px;
  font-size: small;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #777;
}