.member-create-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; 
    width: 100%; 
    padding-top : 5px;
}

.member-create-titie {
    text-align: center;
    width: 100%;
}