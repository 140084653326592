.remaining-debt-value {
    color : firebrick
}

.overpaying-value {
    color : green
}

.payback-label-texts {
    color: #777;
}