.header {
    background-color: #000000;
    width: 100%;
    height: 30px;
    position: relative;
    z-index: 1;
    display: flex;
}

.appLogo a:link {
    text-decoration: none; color: white
}

.appLogo a:visited {
    text-decoration: none; color: white
}

.appLogo a:hover {
    text-decoration: none; color: white
}


#header .appLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica;
    font-size: 1.4rem;
    font-weight: bold;
    max-width: 1000px;
    width: 100%;
    margin: 0 20px auto;
}