.menu-container {
    position: relative;
    /* position: relative; */
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
  }
  
  .menu-toggle {
    display: none;
  }
  
  .menu-icon {
    display: block;
    width: 40px;
    height: 100%;
    position: relative;
    top: 5px;
    left: 7px;
    z-index: 3;
    cursor: pointer;
  }
  
  .menu-icon-line {
    display: block;
    width: 70%;
    height: 2px;
    background-color: #ffffff;
    margin-bottom: 6px;
    transition: transform 0.1s ease-in-out;
  }
  
  .menu {
    position: fixed;
    top: 30px;
    left: 0;
    /* height: 100%; */
    width: 100%;
    height: auto; /* Change height to auto */
    background-color: #fff;
    opacity: 0; /* Set initial opacity to 0 */
    transition: opacity 0.3s ease-in-out;
    overflow: hidden;
    pointer-events: none; /* Add this property to make the menu non-clickable */
    z-index: 2;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3); /* Add box-shadow for the bottom */
  }
  
  .menu-toggle:checked ~ .menu {
    width: 100%;
    top: 3.7%; /* Added this as of 13th June. This can adjust the position of where the menu will appear when toggled. Currently 3.7% seems the best rate*/
    opacity: 1;
    visibility: visible; /* Set visibility to visible on checked state */
    pointer-events: auto; /* Enable pointer events when the menu is toggled */
    transition-delay: 0s; /* Remove delay for visibility transition */
  }
  
  .menu-toggle:checked ~ .menu-icon .menu-icon-line:nth-child(1) {
    background-color: #ffffff;
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .menu-toggle:checked ~ .menu-icon .menu-icon-line:nth-child(2) {
    opacity: 0;
  }
  
  .menu-toggle:checked ~ .menu-icon .menu-icon-line:nth-child(3) {
    background-color: #ffffff;
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  .menu-items {
    list-style: none;
    padding: 0;
    margin: 20px;
  }
  
  .menu-items li {
    margin-bottom: 10px;
  }
  
  .menu-items a {
    text-decoration: none;
    color: #333;
  }
  
  .menu-items a:hover {
    text-decoration: underline;
  }

  .menu-link-text {
    margin-left: 5px;
}