  .inv-link-style {
    font-size: x-small;
    word-wrap: break-word;
    width: 60%;
  }

  .link-icon {
    margin-left: 10px;;
  }

  .link-icon-wrapper {
    cursor: pointer;
  }


  .link-copied-tooltip {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%); 
    padding: 5px 10px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    opacity: 1;
    animation: fadeOut 2s forwards;
  }
  
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }

  .bordered-container {
    /* border-top: 1px solid lightgrey; */
    border-bottom: 1px solid lightgrey;
    padding: 10px 0; /* Add padding to give some space between the border and the content */
  }

  .bordered-container-top {
    border-top: 1px solid lightgrey;
  }

  .bordered-container:hover {
    background-color: lightgray;
  }