.spanDeco {
    cursor:pointer;
    color:blue;
    text-decoration:underline;
}

.loginPageTitle {
    text-align: center;
    margin :30px;
    font-size: 30px;
}

.form-control {
    margin-left:auto;
    margin-right: 200%;
    margin-bottom: 20px;
    width: 80%;
    height: 40px;
}

.login-form-control {
    margin-top: 20px;
    margin-left:auto;
    margin-right: 200%;
    margin-bottom: 20px;
    width: 80%;
    height: 40px;
}

.form-inline {
    text-align: center;
    max-width: 450px;
}

.form-container {
    padding: 32px 40px;
    display: flex;
    width: 100%;
    align-items: center;
}

.form-child {
    margin:0 auto;
    min-width: 300px;
}

.login-button {
    display: inline-block;
    width: 100%;
    padding: 6px 16px;
    font-weight: 300; /* Adjust the font weight as needed */
    color: blue;
    background-color: white;
    border: 1px solid blue;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.login-button:hover {
    color: blue;
    border-color: blue;;
    font-weight: bold;
  }
  
  .login-button:focus {
    box-shadow: 0 0 0 2px black;
  }

.privacy-text {
    font-size: small;
}