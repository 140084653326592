.payment-single-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; 
    width: 100%; 
}

.payment-single-edit-icon {
    padding-right:10px
}

.payment-single-subject {
    font-weight: bold;
    font-size:larger;
    position: relative;
    margin-bottom:30px;
    height: 30px;
    word-wrap: break-word;
}

.payment-single-container {
    padding: 16px;
    width: 100%; /* adjust as needed */
}

.payment-single-amount {
    font-size: 1.2em;
    position: relative;
    text-decoration: underline;
}

.payment-single-payer {
    font-size: medium;
    color: rgb(163, 162, 162);
}

.payment-single-row {
    display: flex;
    justify-content: space-between; /* this separates the children to the two ends of the container */
    align-items: center; /* this vertically centers the children in case they have different heights */
    padding-top: 35px;
}

.payment-single-members-container {
    width: 250px; 
    padding-top: 40px; 
    font-family: 'Arial', sans-serif;
}

.payment-sing-members-title {
    font-size: medium;
}
.payment-single-members-container ul {
    list-style-type: none;   /* Remove default bullet points */
    padding: 0;              /* Remove default padding */
    margin: 0;               /* Remove default margin */
}

.payment-single-members-container li {
    padding: 5px 0;          /* Vertical space between names */
}