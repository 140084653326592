.members-icon {
    padding-left: 10px;
}

.add-member-icon {
    padding-left: 20px;
}

.add-payment-icon {
    padding-left: 20px;
}

.top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Adjust margin as needed */
}

.bottom-row {
    /* Add styles for the row below, e.g., padding, margin, etc. */
}

.icons-container {
    display: flex;
}

.icon-item {
    text-align: center;
    margin-right: 20px; /* Adjust margin as needed */
}

.members-icon {
    padding-left: 10px;
}

.add-member-icon {
    padding-left: 20px;
}

.add-payment-icon {
    padding-left: 20px;
}
