.member-list-cells {
    border-left: none;
    border-right: none;
    width: 300px;
}

.action-list-cells {
    border-left: none;
    border-right: none;
    align-items: center;
    justify-content: space-between;
}

.member-list-actions {
    display: flex;
    align-items: center;
}