.generalbutton {
    display: inline-block;
    padding: 6px 16px;
    font-weight: 300; /* Adjust the font weight as needed */
    color: black;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }

.generalbutton-small {
  display: inline-block;
  font-weight: 350; /* Adjust the font weight as needed */
  color: black;
  background-color: white;
  border: 1.0px solid black;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  font-size: 10px;
  height: 25px;
  width: 50px;
}
  .generalbutton:hover {
    background-color: black;
    color: white;
    border-color: black;
  }
  
  .generalbutton:active {
    box-shadow: 0 0 0 2px black;
    outline: none;
  }
  
.generalbutton,
.generalbutton-small,
.generalbutton:hover,
.generalbutton:focus,
.generalbutton:active {
  text-decoration: none;
}