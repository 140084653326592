.blog-title {
    text-align: center;
    margin-top: 20px;
}

.blog-body-container {
    max-width: 300px; /* Adjust the width as needed */
    margin: 0 auto;   /* Center the container horizontally */
}

.blog-middle-section {
    text-align: left; /* Align text to the left */
}

.with-border {
    border-bottom: 1px solid #ccc; /* Grey border line for this specific paragraph */
  }

.with-bottom-margin {
    margin-bottom: 20px;
}