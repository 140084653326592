.data-box {
    width: 100%;
    /* max-width: 500px; */
    background-color: white;
    padding: 20px;
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: column; 
    position: relative; /* Add position relative */
  }
  
  .data-box:last-child {
    border-bottom: none; /* Remove the border from the last data box */
  }
  
  .event-name a {
    text-decoration: none;
    cursor: pointer;
    font-weight: bold; /* Make the event name bold */
    color: black;
  }
  
  .event-name a:hover {
    color: rgb(58, 57, 57);
  }

  .start-date {
    /* Styling for start date */
  }
  
  .end-date {
    /* Styling for end date */
  }
  
  .num-of-members a {
    text-decoration: none;
    cursor: pointer;
    font-weight: bold; /* Make the event name bold */
    color: black;
    font-size: small;
    position: absolute;
    bottom: 0;
    right: 0; /* Position on the right */
  }

  .num-of-members a:hover {
    color: rgb(58, 57, 57);
  }
  