/* styles.css */

/* Top Section Styles */
.top-section {
    background-color: #01063b; /* Dark Navy */
    color: #fff;
    text-align: center;
    padding: 3rem 0;
  }

.top-section-description {
  max-width: 350px; /* Adjust the width as needed */
  margin: 0 auto; /* Center the element horizontally */
  /* font-size: 1.5rem; */
  padding-bottom: 30px;
}

.top-section-faetures {
  max-width: 340px; /* Adjust the width as needed */
  margin: 0 auto; /* Center the element horizontally */
  font-size: 1.0rem; /* Adjust the font size as needed */
  padding-bottom: 30px;
}

.get-started-button-container {
  margin-top: 50px;
}

/* Styling for the "Get Started" button */
.get-started-button {
  width: 300px; /* Set the width to 300px */
  border: none;
  background-color: #fbf6f6;
  color: #01063b;
  padding: 15px 0; /* Adjust padding for height */
  border-radius: 50px; /* Create rounded edges */
  font-size: 1.2rem; /* Adjust font size as needed */
  cursor: pointer;
  transition: background-color 0.3s;
}

.get-started-button-reverse {
  width: 300px; /* Set the width to 300px */
  border: none;
  color: #fbf6f6;
  background-color: #01063b;
  padding: 15px 0; /* Adjust padding for height */
  border-radius: 50px; /* Create rounded edges */
  font-size: 1.2rem; /* Adjust font size as needed */
  cursor: pointer;
  transition: background-color 0.3s;
}

.get-started-button:hover {
  font-weight: bold; 
}

  /* Middle Section Styles */
  .middle-section {
    background-color: #fff; /* White */
    padding: 3rem 0;
    text-align: center;
    min-height: 86vh;
  }
  
  .middle-section h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .feature {
    margin: 2rem 0;
  }
  
  .feature h3 {
    font-size: 1.5rem;
  }
  
  .feature-description {
    max-width: 300px; /* Set the max width to 300px */
    margin: 0 auto; /* Center the element horizontally */
  }

  .how-to-proceed-description {
    max-width: 300px; /* Set the max width to 300px */
    margin: 0 auto; /* Center the element horizontally */
  }

  .how-to-proceed h3 {
    font-size: 1.5rem;
  }

  .how-to-proceed {
    margin: 2rem 0;
  }
  
  .how-to-get-started {
    max-width: 500px; /* Adjust the max-width according to your design */
    margin: auto;
    padding: 20px; /* Add padding if needed */
  }
  
/* Bottom Section Styles */
.bottom-section {
  background-color: #333; /* Dark Grey */
  color: #fff;
  text-align: left; /* Align to the left */
  padding: 1rem 0;
}

.bottom-links {
  margin-left: 20px;
  list-style: none;
  padding: 0;
  text-align: left; /* Align the text within the list to the left */
}

.bottom-links li {
  display: block; /* Display list items as blocks (vertical) */
  margin-bottom: 0.5rem; /* Add some space between list items */
}

.bottom-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.bottom-links a:hover {
  text-decoration: underline;
}


  /* Copyright Styles */
.splitapp-copyright {
  position: relative;
  color: #bcbaba;
  font-size: 0.8rem;
  margin-left: 20px;
}

.gif-image-with-border {
  border: 2px solid black; /* Adjust the width and color of the border as needed */
  padding: 4px; /* Optional: Add some padding for better visual appeal */
}

.social-links {
  margin-left: 20px;
  margin-bottom: 10px;
}