/* .message-animation {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%); 
    padding: 5px 10px;
    background-color:rgb(62, 61, 61);
    color: #fff;
    border-radius: 4px;
    opacity: 1;
    animation: fadeOut 2s forwards;
} */

.message-animation {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%); 
    padding: 5px 10px;
    background-color: rgb(62, 61, 61);
    color: #fff;
    border-radius: 4px;
    opacity: 1;
    animation: fadeOut 2s forwards;
    white-space: nowrap; 
    display: inline-block; /* Use inline-block to allow dynamic width */
    min-width: 100px; /* Set a minimum width to prevent the content from becoming too narrow */
    max-width: 500px; /* Set a maximum width to prevent the content from becoming too wide */
}