.show-all-payment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; 
  width: 100%; 
  padding-top : 5px;
  padding-bottom: 5px;
}

.show-all-payment-title {
  text-align: center;
  width: 100%;
}

.icon-td {
    display: flex;
    align-items: center;
    justify-content: right; /* Align items horizontally in the center */
  }
  
  .icon-container {
    display: flex;
  }
  
  .nested-td {
    margin-left: 5px; /* Adjust as needed */
    margin-right: 5px; /* Adjust as needed */
    cursor: pointer; /* Set the cursor to pointer */
  }

  .plus-circle-custom {
    position: absolute;
    top: 10px; /* Adjust the top value to set the distance from the top edge */
    right: 10px; /* Adjust the right value to set the distance from the right edge */
    cursor: pointer; /* Set the cursor to pointer */
  }

.payment-thread-container {
    width: 100%;
    /* max-width: 500px; */
    background-color: white;
    /* padding: 20px; */
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: column; 
    position: relative; /* Add position relative */
}

.payment-thread-header {
    font-weight: bold;
    background-color: #e0e0e0;
    padding: 2px;
}

.payment-thread-item {
    background-color: white;
    padding: 5px;
    position: relative; 
    display: flex;
    flex-direction: column;
    /* align-items: center;  */
}

.payment-subject {
    font-weight: bold;
    font-size: small;
    /* display: flex; */
    position: relative;
    /* align-items: flex-start; */
    margin-left: 5px;
    margin-bottom:15px;
    height: 30px;
    width: 375px;
    word-wrap: break-word;
}

.amount {
    display: flex;
    font-size: small;
    position: relative;
    bottom: 2px;
    left: 5px;
}

.payer {
  font-size: x-small;
  color: rgb(163, 162, 162);
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.payment-subject a {
  text-decoration: none;
  cursor: pointer;
  font-weight: bold; /* Make the event name bold */
  color: black;
}

.payment-actions-container-bottom-right {
  position: absolute;
  bottom: 0px;
  right: 10px;
  cursor: pointer;
}

.payment-actions-container-bottom-right-adjacent {
  position: absolute;
  bottom: 1px;
  right: 40px;
  cursor: pointer;
}

.payment-actions-container-top-right {
  position: absolute;
  top: 1px;
  right: 10px;
  cursor: pointer;
}

.payment-target-members {
  margin-left: 7px;
  text-decoration: none;
  /* cursor: pointer; */
  font-weight: bold;
  color: rgb(163, 162, 162);
  font-size: x-small;
  /* position: absolute;
  bottom: 2px;
  left: 5px; */
}

/* Position the "Payment List" text in the center and keep "Add Payment" to the right */
.show-all-payment-title {
  display: flex;
  justify-content: space-between; /* Distribute items to both ends */
  align-items: center; /* Center items vertically */
  font-size: 1.2rem; /* Adjust font size as needed */
}

.payment-list-title {
  font-size: 1rem; /* Adjust the font size as needed */
  text-align: center;
  display: inline-block;
  flex-grow: 1; /* Allow this element to grow and fill the available space */
}

.add-payment-button {
  border: 1px solid #000;
  background-color: transparent;
  color: #01063b;
  padding: 10px;
  border-radius: 50px;
  font-size: 0.7rem;
  cursor: pointer;
  transition: background-color 0.3s;
  flex-shrink: 0; /* Prevent this element from shrinking */
}

.show-all-payment-title > .add-payment-button {
  margin-left: 10px; /* Add margin to separate the button from the text */
}


.toggle-section {
  background-color: #f0f0f0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-label {
  font-weight: bold;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d3d3d3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0bbe2c;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0bbe2c;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
