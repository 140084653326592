.loading-icon-size {
    width: 50px;
    height: 50px;
}

.loading-icon-container {
    display: flex;
    justify-content: center; /* center horizontally */
    align-items: center; /* center vertically */
    height: 70vh;
}