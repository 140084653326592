.transaction-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.debtor, .target {
    flex: 1;
    margin-left: 15px;
}

.arrow {
    margin: 0 15px;
}

.transaction-list-container {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    gap: 15px;  /* Adjust this value to increase or decrease the space between the items */
}

.float-end tr {
    display: flex;
    gap: 10px;
}

.detail-link {
    display: flex;
    justify-content: flex-end;
    align-items: left;
  }